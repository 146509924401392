import React from "react"

export class Header extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    var nav = document.getElementById("header")
    if (window.pageYOffset > 100) {
      nav.classList.remove("white")
    } else {
      nav.classList.add("white")
    }
  }

  render() {
    return (
      <header id="header" className="white">
        <a href="#" className="logo">
          Degu.io
        </a>
        {/* <a className="menu" onclick="openNav()">
        Open menu
      </a>
      <ul id="mobileNav">
        <li>
          <a href="about/">About</a>
        </li>
        <li>
          <a href="case-studies/">Case studies</a>
        </li>
        <li>
          <a href="contact/">Contact</a>
        </li>
        <li>
          <button className="close" onclick="closeNav()">
            Close menu
          </button>
        </li>
      </ul> */}
      </header>
    )
  }
}

export default Header
