import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"

export default () => (
  <Layout>
    <Hero />

    {/* <!-- Homepage content --> */}
    <section>
      <div className="container">
        <div className="col1">
          <h2>About</h2>
          <p>
            Degu IO specialises in finding the solution to match your
            requirements and budget. Solutions that benefit you and your clients
            directly.
          </p>
          <p>We build what you need.</p>
          <p>
            Our engineers have decades of experience in developing and deploying
            applications across multiple sectors. Applications that run in the
            cloud, virtual machines or hardware that is situated where your
            production processes require them.
          </p>
          <p>We build the right thing for you.</p>
          <p>
            Contact us today: <a href="mailto:hello@degu.io">hello@degu.io</a>
          </p>
        </div>
        <div className="col2">
          <h3>OUR SERVICES</h3>
          <ul className="small">
            <li>Digital Product Consultancy</li>
            <li>Software Development and Operations</li>
            <li>Specialists in AWS, NodeJS, ReactJS stack</li>
            <li>Supporting and Transforming Legacy Systems</li>
            <li>Training and Mentoring</li>
            <li>Technical Reviews and Budgeting</li>
            <li>Development Team Recruitment</li>
          </ul>
        </div>
        {/* <div className="col1">
          <button className="btn_main">Full Profile</button>
          <button className="btn_main">Case Studies</button>
        </div> */}
      </div>
    </section>
    {/* <!-- /Homepage content -->  */}
  </Layout>
)
