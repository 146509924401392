import React from "react"
import Helmet from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Degu IO Limited - Digital Product Consultancy</title>
        <link rel="canonical" href="https://degu.io" />
        <meta
          name="description"
          content="DEGU IO is a digital product consultancy. Software and hardware development. Node.js, AWS, React.js, Serverless and on Premises solutions"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="apple-touch-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="android-chrome-192x192.png"
          sizes="192x192"
        />
        <link
          rel="icon"
          type="image/png"
          href="favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="favicon-16x16.png"
          sizes="16x16"
        />
      </Helmet>
      <Header />
      {children}
      <Footer />
      <div className="spine"></div>
    </>
  )
}
export default Layout
