import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SEO from "./SEO"

export default class Hero extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query HeroQuery {
            site {
              siteMetadata {
                description
                taglines
              }
            }
          }
        `}
        render={({
          site: {
            siteMetadata: { description, taglines },
          },
        }) => {
          const tagline = taglines[Math.floor(Math.random() * taglines.length)]

          return (
            <>
              <SEO title={tagline} />
              <section className="hero">
                <div className="container">
                  <div className="">
                    <h1>{tagline}</h1>
                    <p className="intro">{description}</p>
                  </div>
                </div>
              </section>
            </>
          )
        }}
      />
    )
  }
}
