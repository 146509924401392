import React from "react"

function Footer() {
  return (
    <footer>
      <div className="container">
        <h3>Contact Degu</h3>
        <div className="left">
          <p>DEGU IO Ltd</p>
          <p>West Sussex, UK</p>
        </div>
        <div className="left">
          <p>
            <strong>Email:</strong>
            <a href="mailto:hello@degu.io">hello@degu.io</a>
          </p>
        </div>
        <p className="copyright">
          DEGU IO Ltd &copy; {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
